import {navigate} from 'gatsby';
import {Dispatch, SetStateAction} from 'react';
import {ColumnSortingSettings} from '../../interfaces/table';
import {VideoMutationOrderBy} from '../../graphql-types';
import ButtonGroup from '../../components/core/button/buttonGroup';
import ButtonIcon from '../../components/core/button/buttonIcon';
import {
    CopyIcon,
    NavArrowRightIcon,
    XlsIcon,
    YoutubeIcon,
    SubtitlesIcon,
    AudioIcon,
} from '../../components/core/icons';
import {sortingSettings} from '../../helpers/tables';
import {TableHeaderColumnTooltip} from '../../helpers/components/table';
import {VideoModalVariants} from './videos/modals';
import {
    videoNotUploaded,
    copyVideoUrl,
    goToUrlVideo,
    exportVideoInfo,
    goToVideoEdit,
} from './videos/copy';
import videoVersionMappings, {VideoVersion} from './videos/versions';
import {Tooltip} from '../../components/core';
import useAnalyticsLoggerClick from '../../hooks/useAnalyticsLoggerClick';
import VideoIconButtons from './videos/iconButtons';

interface ITableVideosMainColumnsProps {
    channel: string,
    setMutationsToExport: (ids: string[]) => void,
    setOpenedModal: Dispatch<SetStateAction<VideoModalVariants | null>>,
    sorting: ColumnSortingSettings<VideoMutationOrderBy>,
    hasVideoWritePermission: boolean,
}

// eslint-disable-next-line import/prefer-default-export
export const tableVideosMainColumns = ({
    channel,
    setMutationsToExport,
    setOpenedModal,
    sorting,
    hasVideoWritePermission,
}: ITableVideosMainColumnsProps) => {
    const videoTableColumns: any = [
        {
            Header: () => TableHeaderColumnTooltip({
                text: 'MCBT/AID',
                sortingSettings: sortingSettings(sorting, VideoMutationOrderBy.VideoMcbt),
            }),
            hasWidthAuto: true,
            Cell: ({row}: {row: any}) => <strong>{row.original.video.mcbt}</strong>,
            accessor: 'video.mcbt',
        },
        {
            Header: () => TableHeaderColumnTooltip({
                text: 'Theme',
                sortingSettings: sortingSettings(sorting, VideoMutationOrderBy.VideoThemeName),
            }),
            accessor: 'video.theme.name',
        },
        {
            Header: () => TableHeaderColumnTooltip({
                text: 'Local Title',
                sortingSettings: sortingSettings(sorting, VideoMutationOrderBy.Title),
            }),
            accessor: 'title',
        },
        {
            Header: () => TableHeaderColumnTooltip({
                text: 'Type',
                sortingSettings: sortingSettings(sorting, VideoMutationOrderBy.VideoTypeName),
            }),
            accessor: 'video.type.name',
        },
        {
            Header: 'Video version',
            hasWidthAuto: true,
            hasLeftBorder: true,
            noRightPadding: true,
            id: 'version',
            Cell: ({row = {}}: {row: any}) => {
                const {captionFile, audioFile, video} = row.original;

                const videoMapping = videoVersionMappings[video.version as VideoVersion];

                return (
                    <div style={{display: 'flex', gap: '.4rem'}}>
                        {videoMapping && (
                            <span style={{fontWeight: '600', color: videoMapping.color}}>
                                {videoMapping.name}
                            </span>
                        )}
                        {captionFile && (
                            <Tooltip text="This mutation has subtitles">
                                <SubtitlesIcon style={{width: '1rem'}}/>
                            </Tooltip>
                        )}
                        {audioFile && (
                            <Tooltip text="This mutation has audio track">
                                <AudioIcon style={{width: '1rem'}}/>
                            </Tooltip>
                        )}
                    </div>
                );
            },
        },
        {
            Header: '',
            hasWidthAuto: true,
            id: 'actions',
            Cell: ({row = {}}: {row: any}) => {
                const hasYoutubeVideoId = ![
                    '',
                    undefined,
                    null,
                ].includes(row.original.upload.videoId);
                const {videoUrl} = row.original.upload;
                const logAnalyticsClick = useAnalyticsLoggerClick();

                return (
                    <ButtonGroup>
                        <ButtonIcon
                            tooltipText={hasYoutubeVideoId ? copyVideoUrl : videoNotUploaded}
                            icon={CopyIcon}
                            onClick={() => {
                                navigator.clipboard.writeText(videoUrl);
                                logAnalyticsClick(VideoIconButtons.copy);
                            }}
                            disabled={!hasYoutubeVideoId}
                            sidePadding={0.9}
                        />
                        <ButtonIcon
                            tooltipText={hasYoutubeVideoId ? goToUrlVideo : videoNotUploaded}
                            icon={YoutubeIcon}
                            isButton={false}
                            href={videoUrl}
                            target="_blank"
                            disabled={!hasYoutubeVideoId}
                            sidePadding={0.9}
                            onClick={() => {
                                logAnalyticsClick(VideoIconButtons.youtube);
                            }}
                        />
                        <ButtonIcon
                            tooltipText={exportVideoInfo}
                            icon={XlsIcon}
                            onClick={() => {
                                setMutationsToExport([row.original.id]);
                                setOpenedModal(VideoModalVariants.export);
                                logAnalyticsClick(VideoIconButtons.export);
                            }}
                            sidePadding={0.9}
                        />
                    </ButtonGroup>
                );
            },
        },
    ];

    if (hasVideoWritePermission) {
        videoTableColumns.push({
            Header: '',
            hasWidthAuto: true,
            noSidePadding: true,
            id: 'videoLink',
            Cell: ({row = {}}: {row: any}) => (
                <ButtonGroup>
                    <ButtonIcon
                        tooltipText={goToVideoEdit}
                        icon={NavArrowRightIcon}
                        onClick={() => {
                            navigate(
                                `/videos/${channel}/${row.original.video.id}/mutations`,
                                {
                                    state: {
                                        activeMutationId: row.original.id,
                                    },
                                },
                            );
                        }}
                    />
                </ButtonGroup>
            ),
        });
    }

    return videoTableColumns;
};
